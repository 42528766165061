import React, {useRef} from 'react';
import styled from 'styled-components';
import Link from './ExtendedLink';
import { colors, space } from '../consts/style';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, EffectFade } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import { FormattedMessage} from 'react-intl';
import Img from 'gatsby-image';
import ModalWindow from './modal/modal-window'
import { useModalWithData } from '../hooks/modal-hook'
import {  Text, ArrowLeftLink, ArrowRightLink, ArrowLeftIcon, ArrowRightIcon  } from './Elements';
import videoBGMp4 from '../images/Bollene Aventure FINAL.mp4';
import videoBGWebM from '../images/Bollene Aventure FINAL.webmhd.webm';
import Badge from './badge'; 
import Boop from './boop';
import  BtnPrimary  from './buttons/ButtonRounded';
import {useIntl} from 'react-intl';


// install Swiper components
SwiperCore.use([Navigation, EffectFade]);

const WrapperSplash = styled.div`
  height: 100vh;
  display: flex;
  flex-direction:column;
  justify-content: center;
  align-items: center;
  position:relative;
  margin-top:-145px;
  z-index:0;
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction:column;
  justify-content: center;
  align-items: center;
  background: transparent;
  z-index: 1;
  text-shadow: 1px 1px black;
`;

const Title = styled.h1`
  font-family:'Wendy One';
  font-size:7.2rem;
  color:white;
  font-weight:normal;
  text-align:center;
  line-height:0.8;
`;

const SubTitle = styled.h2`
  color:${colors.yellow};
  font-family:'Wendy One';
  font-size:2.4rem;
  font-weight:normal;
  text-align:center;
  margin-top: -1.5rem;
`;

const Horaires = styled.div`
  color:white;
  text-transform:uppercase;
  font-weight:bold;
  font-size:2rem;
  margin:2rem;
  text-align:center;
`;


const Slideshow = styled.div`
       /*display: none;*/
   @media (min-width: 767px) {
  
        display: none;
    }
  position:absolute;
  top:0;
  left:0;
  background-color:#bfbfbf;
  width:100%;
  height:100%;
  .swiper-button-prev {
    top:unset;
    bottom:4.5rem;
    left: 4.2rem;
    font-family: swiper-icons;
    display:none;
  }
  .swiper-button-next {
    top:unset;
    bottom:4.5rem;
    left: calc(4.2rem + 50px + 1.1rem);
        display:none;
  }
`;


const ArrowLeftLinkNav = styled(ArrowLeftLink)`
  position:absolute;
  bottom:4.5rem;
  left: 4.2rem;
/*  z-index:1;*/
  cursor:pointer;
  z-index: 5;
`;

const ArrowRightLinkNav = styled(ArrowRightLink)`
  position:absolute;
  bottom:4.5rem;
  right: 4.2rem ;
  /*z-index:1;*/
  cursor:pointer;
  z-index: 5;
`;

const ImgProjet =styled(Img)`
  height:100%;
`

const GradientOverlay = styled.div`
  position:absolute;
  bottom:0;
  height:100vh;
  width:100%;
  background:transparent linear-gradient(180deg, #00000000 0%, #00000099 100%) 0% 0% no-repeat padding-box;
`


const ActiviteWrapper = styled.div`
  position:absolute;
  bottom:4%;
  left:50%;
  transform:translateX(-50%);
  display:flex;
  text-shadow: 1px 1px black;
  /*z-index: 1;*/
     @media (max-width: 767px) {
  
        display: none;
    }
`

const NewsWrapper = styled.a`
  position:absolute;
  padding:${space.quarter};
  cursor:pointer;
  padding:2rem;
  border-radius:4px;
  background:white;
  border-top:3px solid ${colors.yellow};
  color:${colors.dark};
  right:0;
  top:50%;
  &:hover, &:focus {
    font-weight:700!important;
    color:white;
  }

  h3 {width: 12px;
    word-wrap: break-word;
    text-transform: uppercase;
    letter-spacing: 0;
    line-height: 1;
    font-size: 1.8rem;
  }

`

const EncartBtnWrapper = styled.div`
  position:relative;
  display:flex;
    gap:1rem;
  margin-top:2rem;

`

const WrapVideo = styled.div `
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: ${colors.dark};
  @media (max-width: 767px) {
    display: none;
  }
`

const Video = styled.video `
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
 `

const NewsHomepage = styled.div `
  padding:2rem;
  text-align:center;
  display:flex;
  flex-direction:column;
  align-items: center;
 `

const Splash = ({slides, news, poster}) => {
 /* const intl = useIntl();
  console.log(intl)*/
 /* const handleClick = (guidapKey) => window.GUIDAP.booking.call('accroche-aventure.guidap.co', `${guidapKey}?lang=${intl.locale}`)*/
  const handleClick = (guidapKey) => window.GUIDAP.booking.call('accroche-aventure.guidap.co', guidapKey)

  const prevRef = useRef(null);
  const nextRef = useRef(null);

  const slidesSwiper = [];
  slides.activitesHomepage.map( ( activite, i) =>   
    slidesSwiper.push  (      
        <SwiperSlide key={`slide_${i}`}>
          <ImgProjet fluid={activite.imagePrincipale.fluid}/>
          <GradientOverlay/>
         
        </SwiperSlide>
    )
  )

   const {
        modalOpen,
        selected,
        setSelected,
        setModalState
      } = useModalWithData()

  return (

    <WrapperSplash>
    
     <WrapVideo>
      <Video playsInline autoPlay muted loop poster={poster} id="videoBG">
        <source src={videoBGMp4} type="video/mp4"/>
        <source src={videoBGWebM} type="video/webm" />
      </Video>
     </WrapVideo>
     <GradientOverlay/>
     
      <Slideshow>
      <Swiper
          effect="fade"
          slidesPerView={1}
          loop={1}
          style={{ height: '100%' }}
          navigation
          onInit={(swiper) => {
            swiper.params.navigation.prevEl = prevRef.current;
            swiper.params.navigation.nextEl = nextRef.current;
            swiper.navigation.update();
          }} 
          >
        { slidesSwiper   }
        <ArrowLeftLinkNav ref={prevRef} >
          <ArrowLeftIcon title="Précédent"/>
        </ArrowLeftLinkNav>
         <ArrowRightLinkNav ref={nextRef} >
          <ArrowRightIcon title="Suivant"/>
        </ArrowRightLinkNav>
        </Swiper>
      </Slideshow>
     
      <InnerWrapper>
        <Title><FormattedMessage id="splash__title"/></Title>
        <SubTitle><FormattedMessage id="splash__subtitle"/></SubTitle>
        <Horaires><FormattedMessage id="splash__opening"/></Horaires>
         <BtnPrimary as="a" /*trackingTel*/ href="tel:+33768855950">07 68 85 59 50</BtnPrimary>
        
         </InnerWrapper>
      <ActiviteWrapper>
        {slides.activitesHomepage.map( ( activite, i) =>   
           <Boop scale={1.05} timing={200} key={i} ><Link to={`/nos-activites/${activite.slug}/`}><Badge pictoURL={activite.pictogramme.url} title={activite.titre}/></Link></Boop>
        )}
      </ActiviteWrapper>
    {/*
      <NewsWrapper  onClick={ () => {
                      setSelected(news.edges[0].node)
                      setModalState(true)}}
      > 
         <Boop scale={1.05} timing={200} ><h3 ><FormattedMessage id="new"/></h3> </Boop>
      </NewsWrapper>
    
      <ModalWindow  isActive={modalOpen}
          handleClose={() => setModalState(false)} 
            >     
            { (selected) &&
             <NewsHomepage><h3 >{news.edges[0].node.titre} </h3>
              <Text dangerouslySetInnerHTML={{ __html: news.edges[0].node.contenu }} />
              
              <Img fixed={news.edges[0].node.image.fixed} alt={news.edges[0].node.titre}/><br/>
              <EncartBtnWrapper>
              {news.edges[0].node.boutonLien.map(block => (
                  <React.Fragment key={block.id}>
                    {block.model.apiKey === "bouton" && (
                      block.boutonGuidap ? 
                      <BtnPrimary as="a" href="#" onClick={()=>handleClick(news.edges[0].node.guidapKey)}>{block.texteDuBouton}</BtnPrimary>   

                      :
                      <BtnPrimary
                        external={
                          block.lienExterne ? block.lienExterne : undefined
                        }
                        to={block.lienDuBouton}
                        as={block.lienExterne && "a"}
                      >
                        {block.texteDuBouton}
                      </BtnPrimary>
                      
                     
                    )
                      }
                  </React.Fragment>
                ))}
                </EncartBtnWrapper>
             </NewsHomepage>
            }
            </ModalWindow> 
   */}
    </WrapperSplash>
  );
};

export default Splash;
